@import url("//fonts.googleapis.com/css?family=Montserrat:300,400,700");

html {
  font-size: $unit-font-size; }

body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: $base-font-size;
  font-weight: $font-weight-normal;
  line-height: $base-line-height;
  font-feature-settings: "calt" 1;
  text-rendering: optimizeLegibility; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  line-height: $heading-line-height;
  font-weight: $font-weight-bold; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 $small-spacing;

  &:last-child {
    margin-bottom: 0; } }

p {
  font-weight: $font-weight-light; }

em {
  font-style: italic; }

small {
  font-size: 70%;
  opacity: .75; }

a {
  color: $action-color;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%); } }

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0; }

img,
picture {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%; }

figcaption {
  text-align: center;
  margin: 2em; }
