@font-face {
  font-family: 'end2end';
  src: url("#{$icon-font-path}/end2end.ttf?cgaqog") format("truetype"), url("#{$icon-font-path}/end2end.woff?cgaqog") format("woff"), url("#{$icon-font-path}/end2end.svg?cgaqog#end2end") format("svg");
  font-weight: normal;
  font-style: normal; }

.icon {
  font-family: 'end2end' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-home {
  &:before {
    content: $icon-home; } }

.icon-pencil {
  &:before {
    content: $icon-pencil; } }

.icon-image {
  &:before {
    content: $icon-image; } }

.icon-images {
  &:before {
    content: $icon-images; } }

.icon-camera {
  &:before {
    content: $icon-camera; } }

.icon-headphones {
  &:before {
    content: $icon-headphones; } }

.icon-music {
  &:before {
    content: $icon-music; } }

.icon-play {
  &:before {
    content: $icon-play; } }

.icon-price-tag {
  &:before {
    content: $icon-price-tag; } }

.icon-price-tags {
  &:before {
    content: $icon-price-tags; } }

.icon-barcode {
  &:before {
    content: $icon-barcode; } }

.icon-qrcode {
  &:before {
    content: $icon-qrcode; } }

.icon-cart {
  &:before {
    content: $icon-cart; } }

.icon-phone {
  &:before {
    content: $icon-phone; } }

.icon-phone-hang-up {
  &:before {
    content: $icon-phone-hang-up; } }

.icon-address-book {
  &:before {
    content: $icon-address-book; } }

.icon-envelop {
  &:before {
    content: $icon-envelop; } }

.icon-location {
  &:before {
    content: $icon-location; } }

.icon-location2 {
  &:before {
    content: $icon-location2; } }

.icon-calendar {
  &:before {
    content: $icon-calendar; } }

.icon-keyboard {
  &:before {
    content: $icon-keyboard; } }

.icon-display {
  &:before {
    content: $icon-display; } }

.icon-laptop {
  &:before {
    content: $icon-laptop; } }

.icon-mobile {
  &:before {
    content: $icon-mobile; } }

.icon-mobile2 {
  &:before {
    content: $icon-mobile2; } }

.icon-drawer {
  &:before {
    content: $icon-drawer; } }

.icon-drawer2 {
  &:before {
    content: $icon-drawer2; } }

.icon-floppy-disk {
  &:before {
    content: $icon-floppy-disk; } }

.icon-undo {
  &:before {
    content: $icon-undo; } }

.icon-redo {
  &:before {
    content: $icon-redo; } }

.icon-undo2 {
  &:before {
    content: $icon-undo2; } }

.icon-redo2 {
  &:before {
    content: $icon-redo2; } }

.icon-bubble {
  &:before {
    content: $icon-bubble; } }

.icon-bubbles2 {
  &:before {
    content: $icon-bubbles2; } }

.icon-bubble2 {
  &:before {
    content: $icon-bubble2; } }

.icon-bubbles4 {
  &:before {
    content: $icon-bubbles4; } }

.icon-user {
  &:before {
    content: $icon-user; } }

.icon-users {
  &:before {
    content: $icon-users; } }

.icon-user-tie {
  &:before {
    content: $icon-user-tie; } }

.icon-quotes-left {
  &:before {
    content: $icon-quotes-left; } }

.icon-quotes-right {
  &:before {
    content: $icon-quotes-right; } }

.icon-spinner2 {
  &:before {
    content: $icon-spinner2; } }

.icon-spinner5 {
  &:before {
    content: $icon-spinner5; } }

.icon-spinner8 {
  &:before {
    content: $icon-spinner8; } }

.icon-spinner11 {
  &:before {
    content: $icon-spinner11; } }

.icon-search {
  &:before {
    content: $icon-search; } }

.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; } }

.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; } }

.icon-enlarge {
  &:before {
    content: $icon-enlarge; } }

.icon-shrink {
  &:before {
    content: $icon-shrink; } }

.icon-enlarge2 {
  &:before {
    content: $icon-enlarge2; } }

.icon-shrink2 {
  &:before {
    content: $icon-shrink2; } }

.icon-lock {
  &:before {
    content: $icon-lock; } }

.icon-unlocked {
  &:before {
    content: $icon-unlocked; } }

.icon-wrench {
  &:before {
    content: $icon-wrench; } }

.icon-equalizer2 {
  &:before {
    content: $icon-equalizer2; } }

.icon-cog {
  &:before {
    content: $icon-cog; } }

.icon-cogs {
  &:before {
    content: $icon-cogs; } }

.icon-bug {
  &:before {
    content: $icon-bug; } }

.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; } }

.icon-stats-dots {
  &:before {
    content: $icon-stats-dots; } }

.icon-stats-bars {
  &:before {
    content: $icon-stats-bars; } }

.icon-mug {
  &:before {
    content: $icon-mug; } }

.icon-bin {
  &:before {
    content: $icon-bin; } }

.icon-briefcase {
  &:before {
    content: $icon-briefcase; } }

.icon-airplane {
  &:before {
    content: $icon-airplane; } }

.icon-truck {
  &:before {
    content: $icon-truck; } }

.icon-switch {
  &:before {
    content: $icon-switch; } }

.icon-power-cord {
  &:before {
    content: $icon-power-cord; } }

.icon-clipboard {
  &:before {
    content: $icon-clipboard; } }

.icon-menu {
  &:before {
    content: $icon-menu; } }

.icon-menu2 {
  &:before {
    content: $icon-menu2; } }

.icon-menu3 {
  &:before {
    content: $icon-menu3; } }

.icon-menu4 {
  &:before {
    content: $icon-menu4; } }

.icon-cloud {
  &:before {
    content: $icon-cloud; } }

.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; } }

.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; } }

.icon-cloud-check {
  &:before {
    content: $icon-cloud-check; } }

.icon-earth {
  &:before {
    content: $icon-earth; } }

.icon-link {
  &:before {
    content: $icon-link; } }

.icon-attachment {
  &:before {
    content: $icon-attachment; } }

.icon-eye {
  &:before {
    content: $icon-eye; } }

.icon-eye-blocked {
  &:before {
    content: $icon-eye-blocked; } }

.icon-star-empty {
  &:before {
    content: $icon-star-empty; } }

.icon-star-half {
  &:before {
    content: $icon-star-half; } }

.icon-star-full {
  &:before {
    content: $icon-star-full; } }

.icon-heart {
  &:before {
    content: $icon-heart; } }

.icon-man {
  &:before {
    content: $icon-man; } }

.icon-woman {
  &:before {
    content: $icon-woman; } }

.icon-man-woman {
  &:before {
    content: $icon-man-woman; } }

.icon-warning {
  &:before {
    content: $icon-warning; } }

.icon-notification {
  &:before {
    content: $icon-notification; } }

.icon-question {
  &:before {
    content: $icon-question; } }

.icon-plus {
  &:before {
    content: $icon-plus; } }

.icon-minus {
  &:before {
    content: $icon-minus; } }

.icon-info {
  &:before {
    content: $icon-info; } }

.icon-cancel-circle {
  &:before {
    content: $icon-cancel-circle; } }

.icon-blocked {
  &:before {
    content: $icon-blocked; } }

.icon-cross {
  &:before {
    content: $icon-cross; } }

.icon-checkmark {
  &:before {
    content: $icon-checkmark; } }

.icon-checkmark2 {
  &:before {
    content: $icon-checkmark2; } }

.icon-loop2 {
  &:before {
    content: $icon-loop2; } }

.icon-infinite {
  &:before {
    content: $icon-infinite; } }

.icon-shuffle {
  &:before {
    content: $icon-shuffle; } }

.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2; } }

.icon-arrow-left2 {
  &:before {
    content: $icon-arrow-left2; } }

.icon-circle-up {
  &:before {
    content: $icon-circle-up; } }

.icon-circle-right {
  &:before {
    content: $icon-circle-right; } }

.icon-circle-down {
  &:before {
    content: $icon-circle-down; } }

.icon-circle-left {
  &:before {
    content: $icon-circle-left; } }

.icon-shift {
  &:before {
    content: $icon-shift; } }

.icon-ctrl {
  &:before {
    content: $icon-ctrl; } }

.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked; } }

.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked; } }

.icon-radio-checked {
  &:before {
    content: $icon-radio-checked; } }

.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked; } }

.icon-filter {
  &:before {
    content: $icon-filter; } }

.icon-share {
  &:before {
    content: $icon-share; } }

.icon-new-tab {
  &:before {
    content: $icon-new-tab; } }

.icon-embed {
  &:before {
    content: $icon-embed; } }

.icon-embed2 {
  &:before {
    content: $icon-embed2; } }

.icon-terminal {
  &:before {
    content: $icon-terminal; } }

.icon-share2 {
  &:before {
    content: $icon-share2; } }

.icon-mail {
  &:before {
    content: $icon-mail; } }

.icon-mail2 {
  &:before {
    content: $icon-mail2; } }

.icon-mail3 {
  &:before {
    content: $icon-mail3; } }

.icon-mail4 {
  &:before {
    content: $icon-mail4; } }

.icon-google3 {
  &:before {
    content: $icon-google3; } }

.icon-google-plus {
  &:before {
    content: $icon-google-plus; } }

.icon-google-plus2 {
  &:before {
    content: $icon-google-plus2; } }

.icon-google-plus3 {
  &:before {
    content: $icon-google-plus3; } }

.icon-hangouts {
  &:before {
    content: $icon-hangouts; } }

.icon-google-drive {
  &:before {
    content: $icon-google-drive; } }

.icon-facebook {
  &:before {
    content: $icon-facebook; } }

.icon-facebook2 {
  &:before {
    content: $icon-facebook2; } }

.icon-instagram {
  &:before {
    content: $icon-instagram; } }

.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; } }

.icon-spotify {
  &:before {
    content: $icon-spotify; } }

.icon-telegram {
  &:before {
    content: $icon-telegram; } }

.icon-twitter {
  &:before {
    content: $icon-twitter; } }

.icon-rss {
  &:before {
    content: $icon-rss; } }

.icon-rss2 {
  &:before {
    content: $icon-rss2; } }

.icon-youtube {
  &:before {
    content: $icon-youtube; } }

.icon-vimeo {
  &:before {
    content: $icon-vimeo; } }

.icon-flickr2 {
  &:before {
    content: $icon-flickr2; } }

.icon-dribbble {
  &:before {
    content: $icon-dribbble; } }

.icon-behance {
  &:before {
    content: $icon-behance; } }

.icon-behance2 {
  &:before {
    content: $icon-behance2; } }

.icon-dropbox {
  &:before {
    content: $icon-dropbox; } }

.icon-github {
  &:before {
    content: $icon-github; } }

.icon-trello {
  &:before {
    content: $icon-trello; } }

.icon-wordpress {
  &:before {
    content: $icon-wordpress; } }

.icon-joomla {
  &:before {
    content: $icon-joomla; } }

.icon-tux {
  &:before {
    content: $icon-tux; } }

.icon-appleinc {
  &:before {
    content: $icon-appleinc; } }

.icon-finder {
  &:before {
    content: $icon-finder; } }

.icon-android {
  &:before {
    content: $icon-android; } }

.icon-skype {
  &:before {
    content: $icon-skype; } }

.icon-linkedin {
  &:before {
    content: $icon-linkedin; } }

.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2; } }

.icon-chrome {
  &:before {
    content: $icon-chrome; } }

.icon-firefox {
  &:before {
    content: $icon-firefox; } }

.icon-IE {
  &:before {
    content: $icon-IE; } }

.icon-edge {
  &:before {
    content: $icon-edge; } }

.icon-safari {
  &:before {
    content: $icon-safari; } }

.icon-opera {
  &:before {
    content: $icon-opera; } }

.icon-html-five {
  &:before {
    content: $icon-html-five; } }

.icon-html-five2 {
  &:before {
    content: $icon-html-five2; } }

.icon-css3 {
  &:before {
    content: $icon-css3; } }

.icon-git {
  &:before {
    content: $icon-git; } }
