canvas {
  border: 0;
  padding: 0;
  margin: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  filter: brightness(50%); }
