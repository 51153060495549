ul,
ol {
  margin: 0 0 $small-spacing;
  padding: 0 0 0 $base-spacing;
  list-style-position: inside;
  padding-left: 25px; }

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: bold;
    margin-top: $small-spacing; }

  dd {
    margin: 0; } }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }
