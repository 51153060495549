.site-header {
  background-color: $base-background-color;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid rgba($light-gray-color, .5);
  @include position(fixed, 0 0 null 0);
  min-height: 80px;
  width: 100%;
  z-index: 1000;

  @extend %clearfix;

  @include media($tablet-small) {
    @include position(relative, auto);
    padding: $base-spacing 0;
    min-height: 35px; }

  .logo, a {
    text-transform: lowercase; }

  .navbar {
    float: right; }

  a {
    color: $medium-gray-color;

    &:hover,
    &:focus,
    &:active {
      color: $light-gray-color; } } }

.logo {
  margin: 0;
  padding: 0;
  float: left;
  font-weight: $font-weight-normal;
  font-size: rem(20);
  @include position(fixed, $base-spacing null null $base-spacing);
  z-index: 100;

  @include media($tablet-small) {
    padding: 5px 0;
    @include position(relative, auto); }

  span {
    color: $primary-color; } }
