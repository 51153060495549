.pagination {
  text-align: center;
  margin: $base-spacing 0 0;

  @extend %clearfix;

  li {
    padding: 0 .4em; }

  li, a, span {
    display: inline-block; }

  a, span {
    line-height: 1.2em;
    font-size: rem(12); }

  span {
    color: #aaa;
    font-style: italic; } }
