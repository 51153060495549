.highlight {
  table {
    td {
      padding: 5px; }

    pre {
      margin: 0; } }

  .c, .cd, .cm, .c1 {
    color: #5e5d83;
    font-style: italic; }

  .cp {
    color: #465457;
    font-weight: bold; }

  .cs {
    color: #465457;
    font-weight: bold;
    font-style: italic; }

  .err {
    color: #d4d4d4;
    background-color: #3d5164; }
  .gi {
    color: #a6e22e; }

  .gd {
    color: #f92672; }

  .ge {
    color: #1b1d1e;
    font-style: italic; }

  .gr, .gt {
    color: #f92672; }

  .gh, .go {
    color: #403d3d; }

  .gp {
    color: #66d9ef; }

  .gs {
    font-weight: bold; }

  .gu {
    color: #465457; }

  .k, .kv, .kc, .kd, .kp, .kr, .kt {
    color: #66d9ef;
    font-weight: bold; }

  .kn, .ow, .o {
    color: #f92672;
    font-weight: bold; }

  .mf, .mh, .il, .mi, .mo, .m, .mb, .mx, .se {
    color: #af87ff; }

  .sb, .sc, .sd, .s2, .sh, .si, .sx, .sr, .s1, .ss, .s {
    color: #d7d787; }

  .na {
    color: #a6e22e; }

  .nc, .nd, .ne, .nf {
    color: #a6e22e;
    font-weight: bold; }
  .no {
    color: #66d9ef; }

  .bp, .nb, .ni, .nn, .vc, .vg, .vi, .nv, .w {
    color: #f8f8f2; }

  .nl {
    color: #f8f8f2;
    font-weight: bold; }

  .nt {
    color: #f92672; }

  color: #d4d4d4;
  background-color: #3d5164; }
code {
  color: #d4d4d4;
  background-color: #3d5164; }
