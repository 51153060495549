.hero {
  display: table;
  width: 100%;
  height: 100%; }

.hero-inner {
  width: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  .dot {
    padding: 5px;
    border: 1px solid $light-gray-color;
    width: 30%; }

  h1,
  h3 {
    max-width: 760px;
    display: block; }

  h1 {
    margin-top: .7em;
    font-size: rem(40);
    margin: 0 auto; }

  h3 {
    font-size: rem(20);
    margin: .8em auto 1.2em; }

  h3, em {
    font-weight: $font-weight-light; }

  em {
    color: $primary-color; }

  strong {
    font-weight: $font-weight-bold; } }
