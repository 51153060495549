
html,
body {
  width: 100%;
  height: 100%; }

body {
  background: $base-background-color;
  padding-top: 75px;
  position: relative;

  @include media($tablet-small) {
    padding-top: 0; } }

figure {
  margin: 0;
  padding: 0; }

.main-container {
  background: transparent;
  padding: $base-spacing 0; }

.container {
  max-width: $phone-large;
  margin-left: auto;
  margin-right: auto;
  padding-left: $base-spacing;
  padding-right: $base-spacing;

  @include media($tablet-small) {
    max-width: $tablet-small; }

  @include media($desktop) {
    max-width: 960px; } }
h2, h3 , h4 {
   text-align: center; }
