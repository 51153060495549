.github-corner {
  display: none;

  &:hover .octo-arm {
    animation: octocat-wave 560ms ease-in-out; }

  @include media($tablet-small) {
    display: block; } }

@keyframes octocat-wave {
  0%,100% {
    transform: rotate(0); }

  20%,60% {
    transform: rotate(-25deg); }

  40%,80% {
    transform: rotate(10deg); } }
